@import 'scss/variables';

@font-face {
    font-family: 'PS TT Commons Roman';
    font-weight: 100 900;

    font-display: swap;
    src: url(https://fonts.pluralsight.com/ps-tt-commons/v1/ps-tt-commons-variable-roman.woff2)
        format('woff-variations');
}

#root {
    height: 100%;
}

body {
    min-width: $screen-md; // Medium screen / desktop
    margin: 0;

    font-family: $fontFamily;
    font-feature-settings: 'tnum' on, 'lnum' on;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    background-color: $bodyLightBackground;
}

code {
    font-family: $fontFamilyCode;
}

.product-search {
    width: 100%;
}

.confirm-product-clone {
    ul {
        padding-left: $spacingSmall;

        list-style-type: '- ';

        li {
            margin-left: $spacingSmall;
        }
    }
}

// Global ant override for "row-gap" vertical spacing w/ Flexbox on Ant v4.12.0+
.ant-row {
    &.row-gap-4 {
        margin-top: 2px;
        margin-bottom: 2px;
    }

    &.row-gap-8 {
        margin-top: 4px;
        margin-bottom: 4px;
    }

    &.row-gap-12 {
        margin-top: 6px;
        margin-bottom: 6px;
    }

    &.row-gap-18 {
        margin-top: 9px;
        margin-bottom: 9px;
    }

    &.row-gap-24 {
        margin-top: 12px;
        margin-bottom: 12px;
    }
}
